import '../images/illustrations/decision.svg'
import '../images/illustrations/decision-tree.svg'
import '../images/illustrations/interactive-movie.svg'
import '../images/illustrations/stress-free.svg'

let last_known_scroll_position = 0;
let ticking = false;

const navbarId = 'game-navbar';
const shrinkClass = 'navbar-shrink';

function doSomething(scroll_pos) {
    let offset = window.pageYOffset;
    let navbar = document.getElementById(navbarId);

    const containsShrink = navbar.classList.contains(shrinkClass);

    if ( offset < 75 ) {
        if (containsShrink) navbar.classList.remove(shrinkClass);
    } else {
        if (!containsShrink) navbar.classList.add(shrinkClass);
    }
}

function scrollListener(e) {
    last_known_scroll_position = window.scrollY;

    if (!ticking) {
        window.requestAnimationFrame(function() {
            doSomething(last_known_scroll_position);
            ticking = false;
        });

        ticking = true;
    }
}

window.addEventListener('load', scrollListener);
window.addEventListener('scroll', scrollListener);
